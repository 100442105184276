// 
// user.js
// Use this to write your custom JS
//

const navbar = document.querySelector('.navbar');
const offset = 70;

function setNavbarShadow() {
    if (window.scrollY > offset) {
        navbar.classList.add('shadow-light', 'border-bottom');
    } else {
        navbar.classList.remove('shadow-light', 'border-bottom');
    }
}

document.addEventListener('scroll', setNavbarShadow);